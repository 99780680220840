/*eslint-disable */
// import sModel from '../../Model/s'
import request from '../../Utils/curl'
import Utility from '../../Utils/utility';

const UserProfileExc = {
  /**
   * user_profile_excList
   */
  async user_profile_excList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "user_profile_exc_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at user_profile_excList() and Exception:',err.message)
    }
  },

  /**
   * user_profile_excView
   */
  async user_profile_excView (context, user_profile_excId) {
    try {      
      let post_data = new FormData();
      post_data.append('exc_id', user_profile_excId);
      return await request.curl(context, "user_profile_exc_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at user_profile_excView() and Exception:",err.message)
    }    
  },

  /**
   * user_profile_excAdd
   */
  async user_profile_excAdd (context, userProfileExcObj) {
    try{
      let post_data = new FormData();
      Utility.showLoader = true
      Utility.loadingMsg = "Please wait...."

      let s3Attachments = []
      for (let key in userProfileExcObj) {
        if (key === 'exc_video_link') {
          post_data.append(key, JSON.stringify(userProfileExcObj[key]));
        }
        else if( key === 'exc_attach' && userProfileExcObj.exc_attach && userProfileExcObj.exc_attach.length ){
          for (const attachment of userProfileExcObj.exc_attach) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          post_data.append(key, userProfileExcObj[key]);
        }
      }

      post_data.append('s3Attachments', JSON.stringify(s3Attachments))
      return await request.curl(context, "user_profile_exc_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_profile_excAdd() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * user_profile_excEdit
   */
  async user_profile_excEdit (context, userProfileExcObj) {
    try{
      Utility.showLoader = true
      Utility.loadingMsg = "Please wait...."
      let post_data = new FormData();

      let s3Attachments = []
      for (let key in userProfileExcObj) {
        if (key === 'exc_video_link' && typeof userProfileExcObj[key] === 'object') {
          post_data.append(key, JSON.stringify(userProfileExcObj[key]));
          continue
        }
        else if( key === 'exc_attach' && userProfileExcObj.exc_attach && userProfileExcObj.exc_attach.length ){
          for (const attachment of userProfileExcObj.exc_attach) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          post_data.append(key, userProfileExcObj[key]);
        }
      }

      post_data.append('s3Attachments', JSON.stringify(s3Attachments))
      return await request.curl(context, "user_profile_exc_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_profile_excEdit() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * user_profile_excDelete
   */
  async user_profile_excDelete (context, user_profile_excId) {
    try{
      let post_data = new FormData();
      
      post_data.append('exc_id', user_profile_excId);

      return await request.curl(context, "user_profile_exc_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at user_profile_excDelete() and Exception:',err.message)
    }
  }
}

export {
  UserProfileExc
}
