/*eslint-disable */
<template>
  <b-container fluid class="p-0 program_detail_model">
    <b-modal v-if="showModal || editMode" v-model="showModal" size="lg"  :hideHeaderClose='true' :noCloseOnBackdrop="true" no-close-on-backdrop no-close-on-esc>
      <template #modal-header="">
        <h4>
          Entrance Exam and Test
        </h4>
        <b-button size="sm" class="closeEduModal" @click="CloseProgramModal()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>
      <b-row class="programTextContainer">
        <b-col md="12" class="admissionForm">
          <b-card p-0>
            <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ cvCardTitle }}
              </h4>
            </template>
            <template>
              <div class="row w-100 m-0">
                <ValidationObserver ref="programForm" class="row w-100 m-0">
                  <div class="form-group col-12">
                    <label for="ExamSelect">Select Exam</label>
                    <ValidationProvider name="Select Exam"  v-slot="{ errors }" rules='required'>
                      <select v-model="vmProgramTest.exam_name" class="form-control mb-0" id="Select Exam">
                        <option v-for="(progTest, index) of ProgramAndTest" :key="(index+progTest)" :value="progTest.exam_name">
                          {{ progTest.exam_name }}
                        </option>
                      </select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-for="(progTest, index) of ProgramAndTest" :key="(index+progTest)" :value="progTest.exam_name" class="w-100 row">
                    <template v-if="vmProgramTest.exam_name === progTest.exam_name">
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12">
                        <label for="Exam Date">{{ progTest.exam_date_label }}</label>
                        <ValidationProvider name="Exam Date" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.exam_date" type="date" class="form-control mb-0" id="Exam_Date">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.subject_name_label">
                        <label for="Subject name">{{ progTest.subject_name_label }}</label>
                        <ValidationProvider name="Subject name" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.subject_name" type="text" class="form-control mb-0" id="subject_name">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.reading_score_label">
                        <label for="Reading Score">{{ progTest.reading_score_label }}</label>
                        <ValidationProvider name="Reading Score" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.reading_score" type="number" class="form-control mb-0" id="Reading_Score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.critical_reading_label">
                        <label for="Critical Reading">{{ progTest.critical_reading_label }}</label>
                        <ValidationProvider name="Critical Reading" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.critical_reading" type="number" class="form-control mb-0" id="Ccritical_reading">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.math_score_label">
                        <label for="Math Score">{{ progTest.math_score_label }}</label>
                        <ValidationProvider name="Math Score" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.math_score" type="number" class="form-control mb-0" id="Math">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.science_score_label">
                        <label for="Science Score">{{ progTest.science_score_label }}</label>
                        <ValidationProvider name="Science Score" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.science_score" type="number" class="form-control mb-0" id="science_core">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.english_score_label">
                        <label for="English score">{{progTest.english_score_label}}</label>
                        <ValidationProvider name="English score" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.english_score" type="number" class="form-control mb-0" id="english_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.writing_score_label">
                        <label for="Writing Score">{{ progTest.writing_score_label }}</label>
                        <ValidationProvider name="Writing Score" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.writing_score" type="number" class="form-control mb-0" id="writing_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.evidence_based_reading_writing_score_label">
                        <label for="Evidence-Based Reading and Writing">{{ progTest.evidence_based_reading_writing_score_label }}</label>
                        <ValidationProvider name="Evidence-Based Reading and Writing" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.evidence_based_reading_writing_score" type="number" class="form-control mb-0" id="evidence_based_reading_writing_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.listening_score_label">
                        <label for="Listening">{{progTest.listening_score_label}}</label>
                        <ValidationProvider name="Listening"  v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.listening_score" type="number" class="form-control mb-0" id="listening_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.speaking_score_label">
                        <label for="Speaking">{{ progTest.speaking_score_label }}</label>
                        <ValidationProvider name="speaking" v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.speaking_score" type="number" class="form-control mb-0" id="speaking_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-4 col-sm-6 col-lg-4 col-xs-12" v-if="progTest.total_score_label">
                        <label for="Total Score">{{ progTest.total_score_label }}</label>
                        <ValidationProvider name="Total Score"  v-slot="{ errors }" rules='required'>
                          <input v-model="vmProgramTest.total_score" type="number" class="form-control mb-0" id="total_score">
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </template>
                  </div>
                </ValidationObserver>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="CloseProgramModal()">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" @click="addAdmission()">
          Save
        </b-button>
      </template>
    </b-modal>

    <template v-if="editMode || !showModal">
      <div class="educationCard viewProgramCard">
        <div class="row ml-2 w-100 programDetail pr-3">
          <h4 class="instituteTitle_block">
            <span><i class="fa-solid fa-angles-right primary-color font-size-16"></i>&nbsp;{{vmProgramTest.exam_name}}</span>
            <span class="educationAddIcon" @click="editProgramOpen()">
              <i class="fa-solid ri-pencil-line"></i>
            </span>
          </h4>

          <h6 class="mb-2"><span>Exam Date: {{vmProgramTest.exam_date | dateFormatDDMMYYYY}}</span></h6>

          <div v-for="(progTest, index) of ProgramAndTest" :key="(index)" class="w-100">
            <template v-if="vmProgramTest.exam_name === progTest.exam_name">
              <table class="examTable w-100 ml-0 pl-0 table_scroll">
                <tr class="examTableHeaderRow" >
                  <th v-if="progTest.critical_reading_label">
                    <h6>{{ progTest.critical_reading_label }}</h6>
                  </th>
                  <th v-if="progTest.subject_name_label">
                    <h6>{{ progTest.subject_name_label }}</h6>
                  </th>
                  <th v-if="progTest.reading_score_label">
                    <h6>{{ progTest.reading_score_label }}</h6>
                  </th>
                  <th v-if="progTest.math_score_label">
                    <h6>{{ progTest.math_score_label }}</h6>
                  </th>
                  <th v-if="progTest.science_score_label">
                    <h6>{{ progTest.science_score_label }}</h6>
                  </th>
                  <th v-if="progTest.english_score_label">
                    <h6>{{progTest.english_score_label}}</h6>
                  </th>
                  <th v-if="progTest.writing_score_label">
                    <h6>{{progTest.writing_score_label}}</h6>
                  </th>
                  <th v-if="progTest.listening_score_label">
                    <h6>{{progTest.listening_score_label}}</h6>
                  </th>
                  <th v-if="progTest.speaking_score_label">
                    <h6>{{progTest.speaking_score_label}}</h6>
                  </th>
                  <th v-if="progTest.total_score_label">
                    <h6>{{progTest.total_score_label}}</h6>
                  </th>
                  <th v-if="progTest.evidence_based_reading_writing_score_label">
                    <h6>{{progTest.evidence_based_reading_writing_score_label}}</h6>
                  </th>
                </tr>
                <tr>
                  <td v-if="progTest.critical_reading_label">
                    <p>
                      <span >{{vmProgramTest.critical_reading}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.subject_name_label">
                    <p>
                      <span >{{vmProgramTest.subject_name}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.reading_score_label">
                    <p>
                      <span >{{vmProgramTest.reading_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.math_score_label">
                    <p>
                      <span>{{vmProgramTest.math_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.science_score_label">
                    <p>
                      <span>{{vmProgramTest.science_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.english_score_label">
                    <p>
                      <span>{{vmProgramTest.english_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.writing_score_label">
                    <p>
                      <span>{{vmProgramTest.writing_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.listening_score_label">
                    <p>
                      <span>{{vmProgramTest.listening_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.speaking_score_label">
                    <p>
                      <span>{{vmProgramTest.speaking_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.total_score_label">
                    <p>
                      <span>{{vmProgramTest.total_score}}</span>
                    </p>
                  </td>
                  <td v-if="progTest.evidence_based_reading_writing_score_label">
                    <p>
                      <span>{{vmProgramTest.evidence_based_reading_writing_score}}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </template>
          </div>
        </div>
      </div>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style lang="scss">
input[type="number"], input[type="date"], input[type="text"]  {
  width: 100%;
}
.examTable {
  margin-left: 7px;
  .examTableHeaderRow {
    border-bottom: 1px solid var(--iq-border-light);
    height: 40px;
  }
}
.programTextContainer {
  .admissionForm {
    select {
        display: block;
        width: 100%;
        height: 33px;
        padding: 5px 25px 5px 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.42857143;
        color: #555;
        background: 6px #efefef;
        border: 1px solid #ddd;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    .sub-ttl {
      font-size: 15px;
      margin-top: 0px;
      color: #0B446F;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 24px;
      width: 100%;
      span {
        text-transform: none;
        font-weight: 400;
        font-size: 13px;
        margin-left: 10px;
        color: #666;
      }
    }
    .radioCol {
      label {
        margin-left: 10px;
      }
    }
  }
  .resultAwait {
    input {
      margin-top: 31px;
      margin-right: 4px;
    }
  }
}
.programDetail {
  margin: 0;
  h4 {
    width: 100%;
    .educationAddIcon {
      float: right;
      color: var(--iq-primary);;
    }
  }
  input {
    border: none;
  }
  .cardHeader {
    padding-left: 13px;
    width: 100%;
  }
  hr {
    width: 100%;
  }
}
.viewProgramCard {
  box-shadow: none !important;
  .iq-card-body {
    padding-left: 0 !important;
    padding-top: 2px !important;
  }
}
.card-body{
  padding:0px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { User } from "../../FackApi/api/user.js"
import config from "../../Utils/config.js"
import ProgramAndTest from "../../FackApi/json/ProgramAndTest.json"

export default {
  name: "ProgramTest",
  props: {
    propProgramDetail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserView: {
      default: true,
      type: Boolean
    },
    propAdmId: {
      default: null
    },
    propGideUser: {
      type: Object
    }
  },
  data () {
    return {
      vmProgramTest: {
      },
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admission Response",
      showModal: false,
      cvClassOptions: config.ClassList,
      editMode: false,
      ProgramAndTest: ProgramAndTest
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    if (this.$route.params.user_id) {
      this.gidUserId = this.$route.params.user_id
    }
    else {
      this.gidUserId = this.propGideUser && this.propGideUser.user_id ? this.propGideUser.user_id : this.userData.user_id
    }

    if (this.propProgramDetail) {
      this.vmProgramTest = { ...this.propProgramDetail }
    }

    if (this.propOpenedInModal) {
      this.showModal = true
    }
  },
  methods: {
    /**
     * editProgramOpen
     */
    editProgramOpen () {
      this.showModal = true
      this.editMode = true
    },
    /**
     * addAdmission
     */
    async addAdmission () {
      let params = {
        user_program_test: JSON.stringify(this.vmProgramTest),
        gide_user_id: this.gidUserId
      }

      let userEditResp = await User.useProfileAdd(this, params)
      if (userEditResp.resp_status) {
        this.$emit("emitCloseProgramModal", userEditResp)
        this.editMode = false
        this.showModal = false
      }
    },
    /**
     * CloseProgramModal
     */
    CloseProgramModal () {
      this.editMode = false
      this.showModal = false
      this.$emit("emitCloseProgramModal", false)
    }
  }
}
</script>
