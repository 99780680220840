<template>
  <div class="w-100">
    <!-- Edit Mode: User About Me Description-->
    <b-modal v-model="showEditProfileModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
      <template #modal-header="">
        <h4 class=" mb-1 instituteTitle_block">
          About
        </h4>
        <b-button size="sm" class="closeEduModal"  @click="showEditProfileModal = false">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>
      <ValidationObserver ref="profileForm">
        <form @submit.prevent="onSubmit">
          <div class="row align-items-center">
            <div class="col-12 mb-3">
              <label for="validation_desc" >{{cvUserDescLabel}}</label>
              <b-form-textarea v-model="vmUserFormData.user_desc" rows="2"  max-rows="6" ></b-form-textarea>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <b-toast
          v-model="showToast"
          name="Toast"
          :variant="toastVariant"
          :title="toastTitle">
          <div v-html="toastMsg">
          </div>
      </b-toast>
      <template #modal-footer="">
          <button type="button" @click="editProfile" class="btn btn-primary mr-2 pull-right">
            {{ cvSubmitBtn }}
          </button>
      </template>
    </b-modal><!-- Edit Mode: User About Me Description-->

    <!-- View Mode: User About Me Description-->
    <div v-if="!showEditProfileModal" :key="vmUserFormData.user_desc">
      <h4 v-if="userData.user_id === propUserObj.user_id" class="educationAddIcon pointer" @click="showEditProfileModal = true">
        <i class="fa-solid ri-pencil-line pr-1"></i>
      </h4>
      <span v-if="vmUserFormData.user_desc && vmUserFormData.user_desc.length > 0" v-html="vmUserFormData.user_desc"></span>
      <span v-else>No Info available</span>
    </div><!-- View Mode: User About Me Description-->
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.fa-solid {
  color: var(--iq-primary);
}
.deleteIconSpan {
  margin-left: 15px;
}
.educationAddIcon {
  float: right;
}
</style>

<script>
import { ValidationProvider } from "vee-validate"
import { User } from "../../FackApi/api/user.js"
import ApiResponse from "../../Utils/apiResponse.js"

export default {
  name: "AboutProfile",
  component: {
    ValidationProvider
  },
  props: {
    propUserObj: null
  },
  data () {
    return {
      cvSubmitBtn: "Save Profile",
      cvCancelBtn: "Cancel",
      cvUserDescLabel: "Write a short description about you, your achievements and strengths. This section is visible to Universities.",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      editMode: false,
      showEditProfileModal: false,
      update: 0,
      vmUserFormData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    if (this.propUserObj.user_desc) {
      this.vmUserFormData = this.propUserObj
    }
  },
  methods: {
    /**
     * editProfile
     * This method is used to edit the profile and mobile no
     */
    async editProfile () {
      try {
        const valid = await this.$refs.profileForm.validate()
        if (!valid) {
          return
        }

        let updateStoreData = {
          user_desc: this.vmUserFormData.user_desc.replace(/\n\r?/g, "<br>")
        }

        let profileEditResp = await User.userEdit(this, updateStoreData)
        await ApiResponse.responseMessageDisplay(this, profileEditResp)
        if (profileEditResp && !profileEditResp.resp_status) {
          return false
        }

        if (profileEditResp && profileEditResp.resp_status) {
          this.userData.user_desc = updateStoreData.user_desc
          this.$store.commit("User/setUserProfile", this.userData)
          this.showEditProfileModal = false
        }
      }
      catch (err) {
        console.error("Exception occurred at editprofile() and Exception", err.message)
      }
    }
  }
}
</script>
